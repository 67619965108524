import BootstrapVue from "bootstrap-vue";
import Swal from "sweetalert2";
import Vue from "vue";
Vue.use(BootstrapVue);
const vm = new Vue({});

export function handleApiError(error) {
  if (error.response.status === 409) {
    localStorage.clear();
    Swal.fire({
      icon: "error",
      title: "Your session has expired.",
    }).then((response) => {
      const objKeys = Object.keys(response);
      if (objKeys.includes("isConfirmed") && objKeys.includes("isDismissed")) {
        if (response.isConfirmed === true || response.isDismissed === true) {
          window.location.href = "/";
          return Promise.resolve();
        }
      }
    });
  }

  // if user is unauthenticated
  if (error.response.status === 401) {
    vm.$bvToast.toast("Please login to access the application.", {
      title: "Unauthorized request.",
      variant: "danger",
      solid: true,
    });

    return Promise.resolve();
  }
  if (document.getElementsByClassName("toast").length) {
    return;
  }
}
