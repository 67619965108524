<template>
  <div>
    <!-- Progress Modal -->
    <b-modal
      id="modal-progress"
      ref="modaloutputprogress"
      title="Processing"
      title-class="font-18"
      hide-header
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <strong>Please wait</strong>
      <br />
      <p>Document publishing is currently in progress.</p>
      <b-progress :value="75" :max="100" animated></b-progress>
    </b-modal>
    <!-- Commit Modal -->
    <b-modal
      id="modal-commit"
      v-model="showModal"
      ref="modalcommit"
      title="Commit Output"
      title-class="font-18"
      hide-header
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <div>
        <b-form-group label="Commit Message">
          <b-form-input for="text" v-model="commitMsg"></b-form-input>
        </b-form-group>
        <button class="btn btn-primary btn-sm" v-on:click="commitOutput()">
          Commit
        </button>
      </div>
    </b-modal>
    <div v-if="hideform" class="row justify-content-center">
      <div class="col-md-6">
        <div class="bg-primary text-white"></div>
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="mb-4">Project Name : {{ projectName }}</h5>
            <p>Important! Commit changes to download the output.</p>
            <div class="d-flex justify-content-center pt-1">
              <button
                class="btn btn-primary btn-sm mr-2 mb-2"
                :disabled="disablecommitbutton"
                @click.prevent="githubCommit"
              >
                Commit on GitHub
              </button>
              <button
                v-on:click="downloadFun()"
                class="btn btn-light btn-sm mr-2 mb-2"
                :disabled="disabledownloadbutton"
              >
                Download Output
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Conditional HR -->
    <hr v-if="!this.$store.state.Auth.orgDetails.customPlugi" />
    <div v-if="!hideform" class="card">
      <div class="card-body">
        <form novalidate @submit.prevent>
          <!-- Title Input -->
          <div class="form-group">
            <label>Title <span class="text-secondary">*</span></label>
            <input
              v-validate="'required'"
              required
              v-model="typeform.releaseTitle"
              @keydown.space="preventLeadingSpace"
              name="releaseTitle"
              type="text"
              id="releaseTitle"
              class="form-control"
              placeholder="Enter release title"
              :class="{
                'is-invalid': submitted && $v.releaseTitle.$error,
              }"
            />
            <p
              class="text-danger text-sm"
              v-show="
                errors.has('releaseTitle') &&
                _.find(errors.items, { field: 'releaseTitle' }).rule ==
                  'required'
              "
            ></p>
          </div>
          <!-- Input Source Dropdown -->
          <div class="form-group">
            <label
              >Input Source Ditamap <span class="text-secondary">*</span></label
            >
            <div>
              <select
                class="form-control"
                v-validate="'required'"
                required
                v-model="typeform.inputPath"
              >
                <option value="" disabled selected>
                  Select source Ditamap
                </option>
                <option
                  v-for="data in selectInput"
                  :key="data.path"
                  :value="data.path"
                >
                  {{ data.fileName }}
                </option>
              </select>
              <p
                class="text-danger text-sm"
                v-show="
                  errors.has('typeform.inputPath') &&
                  $v.typeform.inputPath.required
                "
              >
                <span>Input Format selection is required</span>
              </p>
            </div>
          </div>
          <!-- Output Format Dropdown -->
          <div class="form-group">
            <label>Output Format <span class="text-secondary">*</span></label>
            <div>
              <select
                class="form-control"
                v-validate="'required'"
                required
                v-model="typeform.outputFormat"
              >
                <option value="" disabled selected>Select output format</option>
                <option
                  v-for="data in selectOutputFormat"
                  :key="data.id"
                  :value="data.transtype"
                >
                  {{ data.transtype }}
                </option>
              </select>
              <p
                class="text-danger text-sm"
                v-show="
                  errors.has('typeform.outputFormat') &&
                  $v.typeform.outputFormat.required
                "
              >
                <span>Output Format selection is required</span>
              </p>
            </div>
          </div>
          <!-- Buttons for Generating Output and Resetting Form -->
          <div class="form-group text-right mb-0">
            <div>
              <button
                :disabled="
                  !(
                    typeform.outputFormat &&
                    typeform.inputPath &&
                    typeform.releaseTitle
                  )
                "
                class="btn btn-sm btn-primary"
                v-on:click="generateOutputFun(projectPath, workspacePath)"
              >
                Transform
              </button>
              <button
                @click="resetform"
                class="btn btn-light btn-sm m-l-5 ml-1"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Pull Request Modal -->
    <b-modal
      size="lg"
      ref="pull-modal"
      centered
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-escer
      title="Create a Pull Request"
    >
      <div class="d-block">
        <p class="modal-text">Your files are not updated. Update now.</p>
      </div>
      <div class="text-center">
        <button
          class="btn btn-primary mt-2 px-2 py-1 btn-sm"
          block
          @click="createPullreq()"
        >
          Pull Repository
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import _ from "lodash";
import Swal from "sweetalert2";
import checkurl from "../../../../components/urlvalidator";
import { eventBus } from "../../../../main";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
import { axios } from "../../../../api/index";
import JSZip from "jszip";
import listContent from "list-github-dir-content";
import pMap from "p-map";
import pRetry from "p-retry";
import saveFile from "save-file";
export default {
  props: {
    ditaotVersion: String,
  },
  data() {
    return {
      userId: this.$store.state.Auth.userId,
      orgId: this.$store.state.Auth.orgId,
      userName: this.$store.state.Auth.userName,
      typeform: {
        inputPath: "",
        outputPath: "",
        outputFormat: "",
        releaseTitle: "",
      },
      selectInput: null,
      projectName: CryptoJS.AES.decrypt(
        this.$route.params.reponame,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      repouser: CryptoJS.AES.decrypt(
        this.$route.params.repouser,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      brachName: CryptoJS.AES.decrypt(
        this.$route.params.repobranch,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      selectOutputFormat: null,
      submitted: false,
      projectPath: null,
      typesubmit: false,
      workspacePath: null,
      disablebutton: false,
      disablecommitbutton: true,
      disabledownloadbutton: true,
      commitMsg: null,
      hideform: false,
      showModal: false,
      model: "",
      releaseParams: null,
      outputURL: null,
      gitToken: this.$store.state.Auth.gitToken,
      urlParserRegex: /^[/]([^/]+)[/]([^/]+)[/]tree[/]([^/]+)[/](.*)/,
    };
  },
  created() {
    this.getWorkspace();
    this.hideform = false;
  },
  mounted() {
    this.getoutputFormat();
    this.outputURL = `https://github.com/${this.repouser}/${this.projectName}/tree/${this.brachName}/output`;
    this.makedefaultplugin();
    this.hideform = false;
  },
  computed: {
    _() {
      return _;
    },
  },
  methods: {
    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    validateURL() {
      const newRepoUser = CryptoJS.AES.decrypt(
        this.$route.params.repouser,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      const newRepoName = CryptoJS.AES.decrypt(
        this.$route.params.reponame,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      const oldRepoUser = localStorage.getItem("repouser");
      const oldRepoName = localStorage.getItem("reponame");
      if (newRepoName !== oldRepoName || newRepoUser !== oldRepoUser) {
        checkurl(newRepoName);
      }
    },
    createPullreq() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .post(
          `/orguser/workspace/pullGitChanges?projectName=${this.projectName}`
        )
        .then(() => {
          this.$store.getters.client
            .get(`/orguser/workspace/filecontent?path=${this.currentfilePath}`)
            .then((res) => {
              eventBus.$emit("getcontent", {
                content: res.data,
                path: this.currentfilePath,
              });
              eventBus.$emit("textViewContent", {
                content: res.data,
                path: this.currentfilePath,
              });
              eventBus.$emit("clearHistory");
            })
            .catch(() => {});
          this.$refs["pull-modal"].hide();
          loader.hide();
          this.messageToast("Success", "primary", "Pull request completed");
        })
        .catch((err) => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            err.response
              ? err.response.data.message
              : "An error occurred during the pull request."
          );
        });
    },
    async getWorkspace() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(`/orguser/workspace/byuserId?userId=${this.userId}`)
        .then(async (res) => {
          let path = res.data.installedPath + `/${this.projectName}`;
          this.workspacePath = res.data.installedPath;
          this.projectPath = res.data.installedPath + "/" + this.projectName;
          await this.$store.getters.client
            .post(`/orguser/workspace/fetchGitRemoteChanges?path=${path}`)
            .then((res) => {
              if (
                res.data.message.includes("please pull your branch to proceed")
              ) {
                this.$refs["pull-modal"].show();
              }
            });
          let ext = "ditamap";
          await this.$store.getters.client
            .get(`/orguser/workspace/inputfiles?path=${path}&extenssion=${ext}`)
            .then((res) => {
              loader.hide();
              this.selectInput = res.data;
            })
            .catch(() => {
              loader.hide();
            });
          await this.$store.getters.client
            .get(`/orguser/workspace/repotree?path=${path}`)
            .then((tres) => {
              loader.hide();
              this.model = tres.data;
            })
            .catch(() => {
              loader.hide();
            });
        })
        .catch(() => {
          loader.hide();
        });
    },
    makedefaultplugin() {
      const body = {
        userId: this.userId,
        orgId: this.orgId,
        customizationOptions: {},
      };
      this.$store.getters.client
        .post(`/orguser/docstyler/customizePdfOutput`, body)
        .then(() => {})
        .catch(() => {});
      this.$store.getters.client
        .post(`/orguser/docstyler/customizehtmlOutput`, body)
        .then(() => {})
        .catch(() => {});
    },
    async generateOutputFun(path, workspacePath) {
      this.disablebutton = true;
      let bitPath = workspacePath + "/dita-ot-" + this.ditaotVersion + "/bin";
      let outputParams = {
        inputPath: this.typeform.inputPath,
        ditaotVersion: this.ditaotVersion,
        binPath: bitPath,
        outputPath: path + "/output",
        outputFormat: this.typeform.outputFormat,
      };
      this.releaseParams = {
        ditaMapFileName: this.typeform.inputPath.split("/").pop(),
        outputFormat: this.typeform.outputFormat,
        ditaotVersion: this.ditaotVersion,
        orgId: this.orgId,
        userId: this.userId,
        releaseTitle: this.typeform.releaseTitle,
        releasedBy: this.userName,
        projectName: this.projectName,
      };
      await this.$validator.validateAll().then((result) => {
        if (result) {
          this.$refs["modaloutputprogress"].show();
          this.$store.getters.client
            .post(`/orguser/docpublish`, outputParams)
            .then((res) => {
              this.disablebutton = false;
              this.$refs["modaloutputprogress"].hide();
              this.messageToast("Success", "primary", res.data.message);
              this.typeform.inputPath = null;
              this.hideform = true;
              this.typeform.outputFormat = null;
              this.disablecommitbutton = false;
              this.typeform.releaseTitle = null;
            })
            .catch((err) => {
              this.$refs["modaloutputprogress"].hide();
              this.messageToast(
                "invalid request",
                "danger",
                err.response.data.message
              );
            });
        }
      });
    },
    commitOnGithub() {
      this.showModal = true;
    },
    githubCommit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary btn-sm mr-2",
          cancelButton: "btn btn-light btn-sm",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Add description to submit and commit.",
          input: "text",
          showCancelButton: true,
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          confirmButtonColor: "#556ee6",
          cancelButtonColor: "#f46a6a",
          preConfirm: (commitMsg) => {
            return new Promise((resolve, reject) => {
              if (commitMsg.trim() === "") {
                reject(new Error("Please enter a commit message."));
              } else {
                resolve(commitMsg);
              }
            });
          },
          allowOutsideClick: false,
          inputValidator: (value) => {
            return value.trim() !== ""
              ? undefined
              : "Please enter a commit message.";
          },
        })
        .then(({ value: commitMsg }) => {
          if (commitMsg !== undefined) {
            let commitProjectObj = {
              path: this.projectPath,
              message: commitMsg,
              githubUsername: this.$store.state.Auth.githubUsername,
              email: this.$store.state.Auth.userEmail,
            };
            swalWithBootstrapButtons.fire({
              title: "Commit request in progress...",
              allowOutsideClick: false,
              onOpen: () => {},
            });
            Swal.showLoading();
            this.$store.getters.client
              .put(`/orguser/workspace/commit`, commitProjectObj)
              .then((res) => {
                this.disabledownloadbutton = false;
                this.releaseParams.commitSHA = res.data.commitSHA;
                this.releaseParams.owner = CryptoJS.AES.decrypt(
                  this.$route.params.repoowner,
                  secretKey
                ).toString(CryptoJS.enc.Utf8);
                this.$store.getters.client
                  .post(`/orguser/release`, this.releaseParams)
                  .then(() => {
                    this.$store.commit("setRecentPublicationsData", []);
                  })
                  .catch((err) => {
                    this.$refs["modaloutputprogress"].hide();
                    if (
                      err.response &&
                      err.response.data &&
                      err.response.data.message ===
                        "release validation failed: commitSHA: Path `commitSHA` is required."
                    ) {
                      // Do nothing, just return
                      return;
                    } else {
                      // Display the error
                      this.messageToast(
                        "Invalid request",
                        "danger",
                        err.response && err.response.data
                          ? err.response.data.message
                          : "Unknown error occurred"
                      );
                    }
                  });
                swalWithBootstrapButtons.fire({
                  icon: "success",
                  title: "Commit request completed.",
                  text: res.data.message,
                });
              })
              .catch((err) => {
                swalWithBootstrapButtons.fire({
                  icon: "error",
                  title: "Commit failed!",
                  text: err.response.data.message,
                });
              });
          }
        });
    },
    async getoutputFormat() {
      await this.$store.getters.client
        .get(`/plugins`)
        .then((res) => {
          this.selectOutputFormat = res.data;
        })
        .catch(() => {});
    },
    resetform() {
      this.typeform.inputPath = "";
      this.typeform.outputFormat = "";
      this.typeform.releaseTitle = "";
    },
    // Download output folder as a zip
    async downloadFun() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      const zipPromise = new JSZip();
      let user;
      let repository;
      let ref;
      let dir;
      try {
        const parsedUrl = new URL(this.outputURL);
        [, user, repository, ref, dir] = this.urlParserRegex.exec(
          parsedUrl.pathname
        );
      } catch (err) {
        // Handle URL parsing error
        this.messageToast("Invalid request", "danger", "Error parsing URL");
        loader.hide();
        return;
      }
      try {
        const { private: repoIsPrivate } = await this.fetchRepoInfo(
          `${user}/${repository}`
        );
        const files = await listContent.viaTreesApi({
          user,
          repository,
          ref,
          directory: decodeURIComponent(dir),
          token: this.gitToken,
          getFullData: true,
        });
        const controller = new AbortController();
        const fetchPublicFile = async (file) => {
          const response = await axios.get(
            `https://raw.githubusercontent.com/${user}/${repository}/${ref}/${this.escapeFilepath(
              file.path
            )}`,
            {
              signal: controller.signal,
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP ${response.statusText} for ${file.path}`);
          }
          return response.blob();
        };
        const fetchPrivateFile = async (file) => {
          const response = await axios.get(file.url, {
            headers: {
              Authorization: `Bearer ${this.gitToken}`,
            },
            signal: controller.signal,
          });
          const { content } = await response.data;
          const decoder = await fetch(
            `data:application/octet-stream;base64,${content}`
          );
          return decoder.blob();
        };
        let downloaded = 0;
        const downloadFile = async (file) => {
          const localDownload = () =>
            repoIsPrivate ? fetchPrivateFile(file) : fetchPublicFile(file);
          const onFailedAttempt = (error) => {
            error; // Handle download error, if needed
          };
          try {
            const blob = await pRetry(localDownload, {
              onFailedAttempt,
            });
            downloaded++;
            const zip = await zipPromise;
            zip.file(file.path.replace(dir + "/", ""), blob, {
              binary: true,
            });
          } catch (error) {
            // Handle individual download error, if needed
          }
        };
        await pMap(files, downloadFile, {
          concurrency: 20,
        });
        loader.hide();
        const zip = await zipPromise;
        const zipBlob = await zip.generateAsync({
          type: "blob",
        });
        // Check if any files were downloaded successfully
        if (downloaded > 0) {
          this.$router.push("/release");
          await saveFile(
            zipBlob,
            `${user} ${repository} ${ref} ${dir}.zip`.replace(/\//, "-")
          );
        } else {
          // Handle case when no files were downloaded
          this.messageToast(
            "Invalid request",
            "danger",
            "No files were downloaded."
          );
        }
      } catch (error) {
        // Handle other errors
        this.messageToast(
          "Invalid request",
          "danger",
          "An error occurred while downloading."
        );
      }
    },
    async fetchRepoInfo(repo) {
      try {
        const response = await fetch(
          `https://api.github.com/repos/${repo}`,
          this.gitToken
            ? {
                headers: {
                  Authorization: `Bearer ${this.gitToken}`,
                },
              }
            : {}
        );
        if (!response.ok) {
          throw new Error(`HTTP ${response.statusText}`);
        }
        return response.json();
      } catch (error) {
        // Handle errors and display an error message
        this.messageToast(
          "Invalid request",
          "danger",
          "Error fetching repository info"
        );
        throw error; // Re-throw the error to be handled at a higher level if needed
      }
    },
    escapeFilepath(path) {
      return path.replaceAll("#", "%23");
    },
  },
};
</script>
<style scoped>
.modal-text {
  font-weight: 400;
  font-size: 14px;

  color: #17233d;
}

label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
</style>
