<template>
  <div style="min-height: 70vh">
    <!-- Page header component with title and icon -->
    <PageHeader :title="title" :items="item" />
    <div>
      <div class="mr-2 dita-ot-cont px-1 mb-4">
        <span class="dita-ot">DITA-OT Version:</span>
        <span class="dita-ot-version ml-2">{{ ditaOtVersions }}</span>
      </div>
    </div>
    <!-- Modal for committing output to GitHub -->
    <b-modal
      id="modal-commit"
      ref="modalcommit"
      title="Commit Output"
      title-class="font-18"
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <div>
        <b-form-group label="Commit Message">
          <b-form-input for="text" v-model="commitMsg"></b-form-input>
        </b-form-group>
        <button class="btn btn-sm btn-primary" v-on:click="commitOutput()">
          Commit on GitHub
        </button>
      </div>
    </b-modal>
    <!-- Modal for showing progress -->
    <b-modal
      id="modal-progress"
      ref="modaloutputprogress"
      title="Processing"
      hide-header
      title-class="font-18"
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <strong>Please wait</strong>
      <br />
      <p>loading . . .</p>
      <b-progress
        :value="progress"
        :max="100"
        class="custom-progress"
      ></b-progress>
    </b-modal>
    <div class="row justify-content-center">
      <div class="col-md-4 bg-white">
        <!-- GitHub commit form -->
        <div
          class="custom-notifications d-flex justify-content-between align-items-center flex-wrap"
        >
          <div class="custom-title mb-0">Github Commit</div>
        </div>

        <div class="card-body">
          <label>Select Project<span class="text-danger">*</span></label>

          <!-- Multiselect for project selection -->
          <multiselect
            v-model="selectedproject"
            :options="projectlist.map((item) => item.projectName)"
            placeholder="Choose a Project"
            :max-height="150"
          >
          </multiselect>

          <div class="text-right pt-1">
            <!-- Buttons for committing and syncing projects -->
            <button
              type="submit"
              class="btn btn-light btn-sm mb-2 mr-2"
              @click.prevent="syncprojects"
            >
              Sync
            </button>
            <button
              :disabled="!disabledCommit"
              type="submit"
              class="btn btn-primary mb-2 btn-sm"
              @click.prevent="commitOutput"
            >
              Commit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";
import Swal from "sweetalert2";

import { eventBus } from "../../../../main";
import { mapGetters } from "vuex";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      previousRoute: null,
      ditaotVersion: "",
      progress: 0,
      selectedproject: "",
      projectlist: [],
      commitMsg: null,
      userId: this.$store.state.Auth.userId,
      orgId: this.$store.state.Auth.orgId,
      disabledCommit: false,
      title: "DocMigration",
      migrationType: this.$route.params.migrationType,
      item: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "DocMigration",
          active: true,
        },
      ],
    };
  },
  computed: {
    _() {
      return _;
    },
    isCommitButtonDisabled() {
      return this.commitMsg.length === 0;
    },
    ...mapGetters(["ditaOtVersions"]),
  },
  watch: {
    selectedproject: function (newVal) {
      if (newVal) {
        this.disabledCommit = true;
      } else {
        this.disabledCommit = false;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from.path;
    });
  },

  mounted() {
    // Emit an event to update the sidebar
    eventBus.$emit("update-sidebar", "menuitems.docmigration.text");
    this.getprojectslist();
  },
  methods: {
    // Display a toast message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    // Call the 'get' action to fetch project details
    ...mapActions({
      get: "userProjectDetails",
    }),
    // Fetch the list of projects
    getprojectslist() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(
          `/orguser/workspace/syncedprojects?orgId=${this.orgId}&userId=${this.userId}`
        )
        .then((response) => {
          if (response.data && Array.isArray(response.data)) {
            this.projectlist = response.data;
          } else {
            this.messageToast(
              "Error",
              "danger",
              "Received invalid data from the server"
            );
          }
          loader.hide();
        })
        .catch((error) => {
          this.messageToast(
            "Error",
            "danger",
            error.response
              ? error.response.data.message
              : "An error occurred while fetching project names."
          );
          loader.hide();
        });
    },
    // Show the GitHub commit modal
    gitHubCommit() {
      this.$refs["modalcommit"].show();
    },
    // Commit the selected output to GitHub
    commitOutput() {
      this.progress = 0;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary btn-sm mr-2",
          cancelButton: "btn btn-light btn-sm",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Add description to submit and commit. ",
          input: "text",
          showCancelButton: true,
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          preConfirm: (commitMsg) => {
            return new Promise((resolve, reject) => {
              if (commitMsg.trim() === "") {
                reject(new Error("Please enter a commit message."));
              } else {
                resolve(commitMsg);
              }
            });
          },
          allowOutsideClick: false,
          inputValidator: (value) => {
            return value.trim() !== ""
              ? undefined
              : "Please enter a commit message.";
          },
        })
        .then(({ value: commitMsg }) => {
          if (commitMsg !== undefined) {
            let body = {
              commitFolderName: this.selectedproject,
              commitMessage: commitMsg,
            };
            swalWithBootstrapButtons.fire({
              title: "Commit request in progress...",
              allowOutsideClick: false,
              onOpen: () => {},
            });
            Swal.showLoading();
            this.$store.getters.client
              .post(
                `/orguser/docMigration/commit-to-github?migrationType=${this.migrationType}`,
                body,
                {
                  onUploadProgress: (progressEvent) => {
                    this.progress = Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    );
                  },
                }
              )
              .then((res) => {
                this.showCommitBtn = false;
                swalWithBootstrapButtons.fire({
                  icon: "success",
                  title: "Commit request completed.",
                  text: res.data.message || res.data,
                });
                this.$router.push({
                  path: this.previousRoute,
                  query: { alreadyCommit: "committed" },
                });
              })
              .catch((err) => {
                swalWithBootstrapButtons.fire({
                  icon: "error",
                  title: "Commit failed!",
                  text: err.response.data.message,
                });
                this.commitMsg = null;
                this.selectedproject = "";
              });
          }
        });
    },
    // Sync projects with GitHub
    syncprojects() {
      this.progress = 0;
      let body = {
        userId: this.userId,
        orgId: this.orgId,
        gitToken: this.$store.state.Auth.gitToken,
        githubUsername: JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("githubUsername"),
            secretKey
          ).toString(CryptoJS.enc.Utf8)
        ),
      };
      setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 500);
      this.$refs["modaloutputprogress"].show();
      this.$store.getters.client
        .put(
          `/orguser/workspace/sync?migrationType=${this.migrationType}`,
          body
        )
        .then((response) => {
          this.$refs["modaloutputprogress"].hide();
          this.selectedproject = "";
          this.projectlist = [
            ...this.projectlist,
            ...response.data.syncedProjects,
          ];
          this.messageToast(
            "Sync Complete",
            "primary",
            "Your assigned project list is up to date!"
          );
        })
        .catch(() => {
          this.$refs["modaloutputprogress"].hide();
          this.messageToast(
            "Invalid request",
            "danger",
            "Sorry, we were unable to sync your projects at this time. Please check your network connectivity and try again. If the issue persists, please contact our technical support team for further assistance."
          );
        });
    },
  },
};
</script>

<style scoped>
.custom-source {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.project-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(23, 35, 61, 1);
}

.dita-ot-cont {
  margin-top: 0.4rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.dita-ot {
  color: rgba(23, 35, 61, 1);
}

.dita-ot-version {
  color: rgba(105, 111, 121, 1);
}

label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
.custom-notifications {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.custom-progress {
  height: 5px;
}

.card-body {
  padding-top: 0rem;
}
</style>
