<template>
  <div>
    <!-- Page header component with title and icon -->
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <div class="mr-2 dita-ot-cont px-1">
          <span class="dita-ot">DITA-OT Version:</span>
          <span class="dita-ot-version ml-2">{{ ditaOtVersions }}</span>
        </div>
      </div>
    </div>

    <Steps :currentStep="6" :steps="steps" />

    <Tree
      :downloadLink="downloadLink"
      :fileName="fileName"
      :migrationType="migrationType"
      :isCommitted="isCommitted"
    />
  </div>
</template>

<script>
import Tree from "./tree";
import PageHeader from "@/components/pageheader";
import Steps from "./steps.vue";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
import { mapGetters } from "vuex";
import { eventBus } from "../../../../main";

export default {
  components: {
    PageHeader,
    Steps,
    Tree,
  },

  data() {
    return {
      isCommitted: false,
      userId: this.$store.state.Auth.userId,
      orgId: this.$store.state.Auth.orgId,
      ditaotVersion: "",
      downloadLink: CryptoJS.AES.decrypt(
        this.$route.params.downloadLink,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      fileName: CryptoJS.AES.decrypt(
        this.$route.params.fileName,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      migrationType: CryptoJS.AES.decrypt(
        this.$route.params.migrationType,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      title: "DocMigration",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "DocMigration",
          active: true,
        },
      ],
      steps: [
        {
          id: 1,
          title: "Format Type",
          icon_class: "fa-solid fa-file-lines",
        },
        {
          id: 2,
          title: "Upload Files",
          icon_class: "fa fa-folder-open",
        },
        {
          id: 3,
          title: "Pre-flight Check",
          icon_class: "fa-solid fa-plane-arrival",
        },
        {
          id: 4,
          title: "Transformation",
          icon_class: "fa-solid fa-money-bill-transfer",
        },
        {
          id: 5,
          title: "Post-flight Check",
          icon_class: "fa-solid fa-plane-departure",
        },
        {
          id: 6,
          title: "Download",
          icon_class: "fa-solid fa-floppy-disk",
        },
      ],
    };
  },
  mounted() {
    // Emit an event to update the sidebar
    eventBus.$emit("update-sidebar", "menuitems.docmigration.text");
    if (this.$route.query.alreadyCommit) {
      this.isCommitted = true;
    } else {
      this.isCommitted = false;
    }
  },
  computed: {
    ...mapGetters(["ditaOtVersions"]),
  },
  methods: {
    // Display a toast message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    // Function to download the output as a ZIP file
    async downloadZip() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(`/orguser/mardownToDita/downloadOutputZip/zbxds`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.selectedfolder}.zip`);
          document.body.appendChild(link);
          link.click();
          loader.hide();
          this.messageToast("Success", "primary", "Downloaded successfull!");
        })
        .catch((error) => {
          loader.hide();
          this.messageToast("Invalid request", "danger", error.message);
        });
    },
  },
};
</script>
