export const serviceRoutes = (userId, orgId, inputSource, apiType) => {
  const routes = {
    "DOCX to DITA": {
      upload: `/orguser/docxToDita/uploadDocxFile?userId=${userId}&orgId=${orgId}`,
      transform: "/orguser/docxToDita/convertDocxToDita",
      download: "/orguser/docxToDita/downloadOutputZip",
    },
    "HTML to DITA": {
      upload: `/orguser/htmlToDita/uploadHtmlFile?userId=${userId}&orgId=${orgId}`,
      transform: "/orguser/htmlToDita/convertHtmlToDita",
      download: "/orguser/htmlToDita/downloadOutputZip",
    },
    "MD or MDX to DITA": {
      upload: `/orguser/mardownToDita/uploadMarkdownZipFile?userId=${userId}&orgId=${orgId}`,
      transform: "/orguser/mardownToDita/convertMarkdownToDita",
      download: "/orguser/mardownToDita/downloadOutputZip",
    },
  };

  return routes[inputSource][apiType];
};
