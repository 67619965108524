<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
<template>
  <div
    class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between pb-3 page-title-box"
  >
    <h5 class="mb-sm-0">{{ title }}</h5>
    <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 280px) {
  * {
    font-size: 10px;
  }
}
</style>
