// Import necessary components and libraries
import DocMigration from "../docmigration/index.vue";
import PopUpMessage from "./components/popupmessage.vue";
import outputscreen from "./components/outputscreen.vue";
import githubcommit from "./components/githubcommit.vue";

import { checkAccessToService } from "../../../middleware/secure-route";

// Define an array of route objects
export default [
  {
    // Define a route for the DocMigration component
    path: "/docmigration",
    component: DocMigration,
    name: "DocMigration",
    beforeEnter: (to, from, next) => {
      checkAccessToService(to, from, next, "DocMigration");
    },
  },
  {
    // Define a route for the PopUpMessage component
    path: "/noaccess",
    component: PopUpMessage,
    name: "PopUpMessage",
  },
  {
    // Define a route for the outputscreen component with dynamic parameters
    path: "/docmigration/outputscreen/:downloadLink/:fileName/:migrationType",
    component: outputscreen,
    name: "outputscreen",
    beforeEnter: (to, from, next) => {
      checkAccessToService(to, from, next, "DocMigration");
    },
  },
  {
    // Define a route for the githubcommit component with dynamic parameters
    path: "/docmigration/outputscreen/githubcommit/:migrationType",
    component: githubcommit,
    name: "githubcommit",
    beforeEnter: (to, from, next) => {
      checkAccessToService(to, from, next, "DocMigration");
    },
  },
];
