<template>
  <div class="container-fluid">
    <button
      type="button"
      class="btn customBtn"
      v-b-tooltip.hover
      title="Save Output File"
      id="save"
      variant="light"
      @click="downloadZip"
    >
      <i class="fa-solid fa-circle-down"></i>
    </button>
    <button
      type="button"
      class="btn customBtn"
      v-b-tooltip.hover
      title="Commit on Github"
      id="github"
      variant="light"
      @click="gitHubCommit"
    >
      <i class="fa-brands fa-github"></i>
    </button>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="custom-source d-flex justify-content-between align-items-center flex-wrap"
                >
                  <div class="custom-title mb-0">DITA Output</div>
                </div>
                <div class="border-top pt-3 infoDiv folderStructure">
                  <SimpleBar class="custom-jstree p-3">
                    <v-jstree
                      :data="treeData"
                      @item-click="itemClick"
                    ></v-jstree>
                  </SimpleBar>
                </div>
              </div>
              <div class="col-md-6 mt-3 mt-md-0">
                <div
                  class="custom-source d-flex justify-content-between align-items-center flex-wrap"
                >
                  <div class="custom-title mb-0">Content Preview</div>
                </div>

                <div
                  class="border-top pt-3 infoDiv"
                  v-if="selectedFile && selectedFile.content"
                >
                  <SimpleBar class="custom-jstree p-3">
                    <ssh-pre language="xml">
                      {{ getIndentedContent() }}
                    </ssh-pre>
                  </SimpleBar>
                </div>

                <div
                  v-else
                  class="d-flex justify-content-center align-items-center no-output-cont"
                >
                  <div class="mb-5 text-center">
                    <img
                      class="custom-img"
                      src="../../../../assets/placeholder.png"
                    />
                    <div class="no-output-text">File not selected.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VJstree from "vue-jstree";
import xmlFormat from "xml-formatter";
import SshPre from "simple-syntax-highlighter";
import "simple-syntax-highlighter/dist/sshpre.css";
import SimpleBar from "simplebar-vue";
import Swal from "sweetalert2";

export default {
  name: "FileTree",
  components: {
    VJstree,
    SshPre,
    SimpleBar,
  },

  props: {
    downloadLink: {
      type: String,
    },
    fileName: {
      type: String,
    },
    migrationType: {
      type: String,
    },
    isCommitted: {
      type: Boolean,
    },
  },

  data() {
    return {
      selectedFile: null,
      displayLog: false,
      logBtnText: "View Log",
      treeData: [],
    };
  },

  methods: {
    itemClick(node) {
      if (node.model.content) {
        this.selectedFile = node.model;
      } else {
        this.selectedFile = null;
      }
      const treeSelectedElements = document.querySelectorAll(".tree-selected");
      treeSelectedElements.forEach(function (el) {
        el.classList.remove("tree-selected");
      });
    },
    xmlFormat,
    getIndentedContent() {
      const ext = this.selectedFile.text.split(".").pop();
      if (ext.startsWith("dita") || ext.startsWith("xml")) {
        return this.xmlFormat(this.selectedFile.content);
      } else {
        return this.selectedFile.content;
      }
    },
    onCopiedDoSomething() {
      this.$bvToast.toast("copied to clipboard!", {
        title: "Copied",
        variant: "success",
        solid: true,
      });
    },
    toggleLog() {
      this.displayLog = !this.displayLog;
      this.logBtnText = this.displayLog ? "Hide Log" : "View Log";
    },
    async downloadZip() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(this.downloadLink, {
          responseType: "blob",
        })
        .then((response) => {
          const generateName = (fileName) => {
            const lastDotPosition = fileName.lastIndexOf(".");
            const nameWithoutExtension = fileName.substring(0, lastDotPosition);
            return nameWithoutExtension + ".zip";
          };
          const downloadFilename = generateName(this.fileName);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", downloadFilename);
          document.body.appendChild(link);
          link.click();
          loader.hide();
          this.messageToast("Success", "primary", "Downloaded successfull!");
        })
        .catch((error) => {
          loader.hide();
          this.messageToast("Invalid request", "danger", error.message);
        });
    },
    gitHubCommit() {
      if (this.isCommitted) {
        Swal.fire({
          icon: "warning",
          title: "Commit request failed.",
          text: "Output is already committed.",
        });
      } else {
        this.$router.push({
          path: `/docmigration/outputscreen/githubcommit/${this.migrationType}`,
        });
      }
    },
    async getTreeStructure() {
      try {
        const URL = `/orguser/docMigration/zip-tree-structure?migrationType=${this.migrationType}`;
        const response = await this.$store.getters.client.get(URL);
        this.treeData = response.data.tree;
        this.selectedFile = this.treeData[0].children.find((file) => {
          const ext = file.text.split(".").pop();
          if (ext == "ditamap") {
            file.selected = true;
            return file;
          }
        });
      } catch (error) {
        this.messageToast(
          "Something went wrong",
          "danger",
          error.response.data.message
        );
      }
    },
  },
  mounted() {
    this.getTreeStructure();
  },
};
</script>

<style scoped>
.custom-source {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.project-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(23, 35, 61, 1);
}

.dita-ot-cont {
  margin-top: 0.4rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.dita-ot {
  color: rgba(23, 35, 61, 1);
}

.dita-ot-version {
  color: rgba(105, 111, 121, 1);
}

label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.custom-notifications {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.card-body {
  padding-top: 0rem;
}
.custom-img {
  height: 200px;
}
.dita-log {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
  overflow-wrap: break-word;
  max-height: 70vh;
  overflow: auto;
}
.customBtn i {
  font-size: 30px;
  color: red;
}
.custom-jstree {
  height: 70vh;
  overflow: auto;
}
</style>
<style>
.folderStructure .custom-structure-img {
  width: 18px !important;
  height: 18px !important;
}
.folderStructure .custom-structure-img path,
.customBtn path {
  fill: #0f3460;
}

.customBtn svg {
  scale: 1.3;
}
.ssh-pre,
.ssh-pre__content {
  margin: 0 !important;
}
.infoDiv.folderStructure .tree-container-ul {
  width: 100vw;
}
</style>
