<template>
  <b-modal
    id="doc-con-policy"
    scrollable
    size="lg"
    title="document conversion policy"
  >
    <ol v-if="documentPolicy.length > 0">
      <li v-for="(policy, index) in documentPolicy" :key="index">
        <p>
          <b> {{ policy.attributes.Question }}</b>
        </p>
        <div v-html="policy.attributes.Answer"></div>
      </li>
    </ol>
  </b-modal>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      documentPolicy: [],
    };
  },
  methods: {
    async getDocumentPolicy() {
      const response = await axios.get(
        "https://admin.met-r.io/api/privacy-policies?populate=*"
      );
      this.documentPolicy = response.data.data;
    },
  },
  mounted() {
    this.getDocumentPolicy();
  },
};
</script>

<style scoped>
#doc-con-policy p {
  margin-bottom: 8px;
}
</style>
