<template>
  <b-modal
    id="pre-flight-invalid-file"
    scrollable
    title="Following files does not content title."
  >
    <ol>
      <li v-for="(file, index) in files" :key="index">
        <p>{{ file }}</p>
      </li>
    </ol>
  </b-modal>
</template>

<script>
export default {
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
#pre-flight-invalid-file p {
  margin-bottom: 8px;
  word-break: break-word;
}
</style>
