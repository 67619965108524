<template>
  <div>
    <ol
      class="step-indicator d-flex justify-content-between align-items-center mt-4"
    >
      <div class="backtrace">
        <div class="cover"></div>
      </div>
      <li
        v-for="step in steps"
        :key="step.id"
        :class="stepWrapperClass(step.id)"
      >
        <div class="step"><i :class="step.icon_class"></i></div>
        <div class="caption">
          <span>{{ step.title }}</span>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },

  methods: {
    stepWrapperClass(step) {
      return {
        active: step === this.currentStep,
        complete: step < this.currentStep,
      };
    },
  },
  watch: {
    currentStep: function () {
      const div = document.querySelector(".backtrace .cover");
      div.style.width = `${(this.currentStep - 1) * 20}%`;
    },
  },
};
</script>

<style scoped>
.step-indicator {
  --step: #a8a8a8;
  --step-active: #fe5e45;
  --step-complete: #0f3460;
  position: relative;
  text-align: center;
  list-style-type: none;
  padding-left: 0;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
}
.step-indicator .backtrace {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--step-complete);
  z-index: 0;
}
.step-indicator .backtrace .cover {
  height: 100%;
  background-color: var(--step-complete);
  width: 0%;
}
.step-indicator li {
  position: relative;
}

.step-indicator li .caption {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.step-indicator li.active .step {
  border-color: var(--step-active);
  color: var(--step-active);
}
.step-indicator li.active .caption {
  color: var(--step-active);
}

.step-indicator li.complete .step {
  border-color: var(--step-complete);
  color: var(--step-complete);
}
.step-indicator li.complete .caption {
  color: var(--step-complete);
}
.step-indicator .step {
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid var(--step-complete);
  color: var(--step-complete);
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  width: 40px;
  position: relative;
  z-index: 1;
}

.step-indicator .caption {
  color: var(--step-complete);
  width: max-content;
  padding: 11px 16px;
}

@media (max-width: 700px) {
  .step-indicator {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .step-indicator .backtrace {
    display: none;
  }
  .step-indicator .caption {
    padding: 5px;
    background-color: #f1f5f7;
    z-index: 1;
    margin-top: 10px;
  }
  .step-indicator li {
    margin-bottom: 50px;
  }
  .step-indicator li::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    height: 50px;
    width: 2px;
    background-color: var(--step-complete);
  }
  .step-indicator li:last-child::after {
    height: 25px;
  }
  .step-indicator li.active::after {
    background-color: var(--step-active);
  }

  .step-indicator li.complete::after {
    background-color: var(--step-complete);
  }
}
</style>
