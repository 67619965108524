<template>
  <!-- Card component for displaying user roles in projects -->
  <div>
    <div class="card">
      <div
        class="custom-notifications d-flex justify-content-between align-items-center flex-wrap"
      >
        <div class="custom-title mb-0">Roles</div>
        <div>
          <button
            class="btn text-primary view-details-btn btn-sm m-0"
            @click="viewMore"
          >
            <span class="mdi mdi-eye-outline mr-2"></span> View details
          </button>
        </div>
      </div>
      <div data-simplebar class="card-body border-top py-2 mb-3">
        <div class="custom-height">
          <!-- Show skeleton loading table when 'showSpinner' is true -->
          <b-skeleton-table
            v-if="showSpinner"
            :rows="5"
            :columns="2"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
          <!-- Render project roles when data is available and not loading -->
          <div v-else-if="projectsWithRole.length > 0 && !showSpinner">
            <div class="table-responsive">
              <table class="table table-hover font-size-14 mb-0">
                <thead>
                  <tr>
                    <th>
                      <div class="font-weight-bold font-size-16">Project</div>
                    </th>
                    <th>
                      <div class="font-weight-bold font-size-16">Role</div>
                    </th>
                  </tr>
                  <!-- Display "View More" button -->
                </thead>
                <tbody>
                  <!-- Loop through projects and their associated roles -->
                  <tr v-for="projectId in projectsWithRole" :key="projectId.id">
                    <td>
                      <h5
                        class="font-size-13 text-uppercase ptitle-cust-cls mb-0 custom-project-name"
                      >
                        {{ projectId.projectName }}
                      </h5>
                    </td>
                    <td>
                      {{ projectId.userRole.join(", ") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Display a message when no projects are added -->
          <li v-else class="list-unstyled activity-wid">
            <div
              class="d-flex justify-content-center align-items-center m-0 p-0"
            >
              <div class="text-center">
                <img
                  class="custom-image"
                  src="../../../../assets/placeholder.png"
                />
                <div class="custom-text">No projects added</div>
              </div>
            </div>
          </li>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- Rest of your script and style tags remain unchanged -->
<script>
export default {
  data() {
    return {
      showSpinner: true,
      modalShow: false,
      projectsWithRole: null, // List of projects with user roles
      userId: null,
    };
  },
  mounted() {
    // Get the user's ID from the store
    this.userId = this.$store.state.Auth.userId;
    // Check if project data with roles is available in the store, if not, fetch data
    if (this.$store.state.Auth.projectsData.length) {
      this.showSpinner = false;
      this.projectsWithRole = this.$store.state.Auth.projectsData.slice(0, 4);
    } else {
      this.getProjectsWithRole(); // Fetch projects with user roles
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    viewMore() {
      this.$router.push({
        name: "userrole",
      });
    },
    // Function to fetch projects with user roles
    getProjectsWithRole() {
      this.$store.getters.client
        .get(`/projectuser/byuserid?userId=${this.userId}`)
        .then((response) => {
          if (response.data) {
            this.showSpinner = false;
            this.projectsWithRole = response.data.slice(0, 4);
            this.$store.commit("setProjectsData", response.data);
          } else {
            this.messageToast(
              "Invalid request",
              "danger",
              "No data received from the server"
            );
            this.showSpinner = false;
          }
        })
        .catch((err) => {
          this.messageToast(
            "Invalid request",
            "danger",
            err.response ? err.response.data.message : "An error occurred"
          );
          this.showSpinner = false;
        });
    },
  },
};
</script>
<style scoped>
.custom-notifications {
  padding: 14px;
  gap: 24px;
}
.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
.view-details-btn {
  background-color: rgba(113, 165, 203, 0.2);
}

.card {
  height: 24.3rem;
}

.custom-card-header {
  background-color: #3f979b;
}

.custom-project-name {
  font-size: 13px;
  text-transform: uppercase;
}
.custom-image {
  width: min(90%, 250px);
}
.custom-text {
  color: #666;
  font-size: 16px;
}
</style>
