import BootstrapVue from "bootstrap-vue";
import Vue from "vue";
Vue.use(BootstrapVue);
const vm = new Vue({});
import { axios } from "../api";
import { URL } from "@/api/global.env.js";
import CryptoJS from "crypto-js";
import { handleApiError } from "../apiErrorHandler";
import { secretKey } from "../api/global.env";

export const checkAccessToService = (to, from, next, serviceName) => {
  // Before entering this route, fetch user data from the API
  const decryptData = (encryptedData) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (error) {
      return null;
    }
  };
  const userId = decryptData(localStorage.getItem("userId"));
  axios
    .get(`${URL}api/orguser/details?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${decryptData(localStorage.getItem("token"))}`,
        sessionId: decryptData(localStorage.getItem("sessionId")),
        userId: decryptData(localStorage.getItem("userId")),
        orgId: decryptData(localStorage.getItem("orgId")),
      },
    })
    .then((res) => {
      if (res.data && res.data.userRole.includes(serviceName)) {
        // If the user has the DocMigration role, allow access to this route

        next();
      } else {
        // If the user does not have the DocMigration role, disable this route
        vm.$bvToast.toast(`${serviceName} role is not assigned to you.`, {
          title: "Unassigned role",
          variant: "danger",
          autoHideDelay: 2000,
          solid: true,
        });
        next(from.path);
      }
    })
    .catch((error) => {
      handleApiError(error);
      next(from.path); // Disable this route
    });
};
