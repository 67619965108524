var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"d-sm-flex justify-content-between align-items-center"},[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"mr-2 px-1"},[_c('span',{staticClass:"project-name"},[_vm._v(_vm._s(_vm.projectName))])]),_c('div',{staticClass:"mr-2 dita-ot-cont px-1"},[_c('span',{staticClass:"dita-ot"},[_vm._v("DITA-OT Version:")]),_c('span',{staticClass:"dita-ot-version ml-2"},[_vm._v(_vm._s(_vm.ditaOtVersions))])])]),_c('div',[(_vm.ditaTreeData && _vm.ditaTreeData[0]?.children?.length > 0)?_c('button',{staticClass:"btn btn-primary btn-sm mr-3 mb-2",attrs:{"type":"submit"},on:{"click":function($event){return _vm.downloadZip()}}},[_vm._m(0)]):_vm._e(),(
          _vm.isDocEditor && _vm.ditaTreeData && _vm.ditaTreeData[0]?.children?.length > 0
        )?_c('button',{staticClass:"btn btn-primary btn-sm mb-2",attrs:{"type":"submit"},on:{"click":function($event){return _vm.openEditor()}}},[_vm._m(1)]):_vm._e()])]),_c('div',{staticClass:"row mb-2 mt-3"},[_vm._m(2),_c('div',{staticClass:"col-lg-12"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"form-control",attrs:{"placeholder":"Select Branch"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.getObject]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Please Choose Branch")]),_vm._l((_vm.repobranchesdata),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])})],2)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_vm._m(3),_c('div',{staticClass:"border-top mt-1 infoDiv folderStructure"},[(
                        _vm.ditaTreeData && _vm.ditaTreeData[0]?.children?.length > 0
                      )?_c('simplebar',{staticClass:"pt-1 custom-jstree p-3"},[_c('v-jstree',{attrs:{"data":_vm.ditaTreeData},on:{"item-click":_vm.itemClick}})],1):[_c('div',{staticClass:"d-flex justify-content-center align-items-center no-output-cont"},[_c('div',{staticClass:"mb-5 no-output-div"},[_c('img',{staticClass:"custom-img",attrs:{"src":require("../../../assets/placeholder.png")}}),_c('div',{staticClass:"no-output-text"},[_vm._v(" Repository is empty. ")])])])]],2)]),(_vm.ditaTreeData && _vm.ditaTreeData[0]?.children?.length > 0)?_c('div',{staticClass:"col-md-6 mt-3 mt-md-0"},[_vm._m(4),(_vm.displayDitaContent)?_c('div',{staticClass:"border-top mt-1 infoDiv"},[_c('simplebar',{staticClass:"custom-jstree p-3"},[_c('ssh-pre',{attrs:{"language":"xml"}},[_vm._v(" "+_vm._s(_vm.displayDitaContent)+" ")])],1)],1):[_vm._m(5)]],2):_vm._e()])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',[_c('i',{staticClass:"mdi mdi-content-save mdi-16px"})]),_c('span',{staticClass:"ml-2"},[_vm._v(" Download Source Files ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',[_c('i',{staticClass:"mdi mdi-file-edit mdi-16px"})]),_c('span',{staticClass:"ml-2"},[_vm._v(" DocEditor ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-label ml-3"},[_vm._v("Select Branch "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-source d-flex justify-content-between align-items-center flex-wrap"},[_c('div',{staticClass:"custom-title mb-0"},[_vm._v("Source")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-source d-flex justify-content-between align-items-center flex-wrap"},[_c('div',{staticClass:"custom-title mb-0"},[_vm._v("Content Preview")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center align-items-center no-output-cont"},[_c('div',{staticClass:"mb-5 no-output-div"},[_c('img',{staticClass:"custom-img",attrs:{"src":require("../../../assets/placeholder.png")}}),_c('div',{staticClass:"no-output-text"},[_vm._v("No file selected.")])])])
}]

export { render, staticRenderFns }