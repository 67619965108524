<template>
  <div>
    <!-- Page Header component with props -->
    <PageHeader :title="title" :items="items" />
    <div>
      <div class="mr-2 dita-ot-cont px-1">
        <span class="dita-ot">DITA-OT Version:</span>
        <span class="dita-ot-version ml-2">{{ ditaOtVersions }}</span>
      </div>
    </div>
    <Details
      :currentStep="currentStep"
      :steps="steps"
      @stepChanged="stepChanged"
    />
  </div>
</template>

<script>
import Details from "./components/details.vue";
import { mapGetters } from "vuex";

import { eventBus } from "../../../main";
export default {
  components: {
    Details,
  },
  data() {
    return {
      // Initialize data properties
      ditaotVersion: "", // DITA-OT version
      title: "DocMigration",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "DocMigration",
          active: true,
        },
      ],
      currentStep: 1,
      steps: [
        {
          id: 1,
          title: "Input Source Type",
          icon_class: "fa-solid fa-file-lines",
        },

        {
          id: 2,
          title: "Upload Files",
          icon_class: "fa fa-folder-open",
        },
        {
          id: 3,
          title: "Pre-flight Check",
          icon_class: "fa-solid fa-plane-arrival",
        },

        {
          id: 4,
          title: "Transformation",
          icon_class: "fa-solid fa-money-bill-transfer",
        },
        {
          id: 5,
          title: "Post-flight Check",
          icon_class: "fa-solid fa-plane-departure",
        },
        {
          id: 6,
          title: "Download",
          icon_class: "fa-solid fa-floppy-disk",
        },
      ],
    };
  },
  methods: {
    stepChanged(step) {
      this.currentStep = step;
    },
  },
  computed: {
    ...mapGetters(["ditaOtVersions"]), // Using Vuex getter
  },
  mounted() {
    // Emit event to update the sidebar menu
    eventBus.$emit("update-sidebar", "menuitems.docmigration.text");
  },
};
</script>
<style scoped>
.custom-source {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.project-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(23, 35, 61, 1);
}

.dita-ot-cont {
  margin-top: 0.4rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.dita-ot {
  color: rgba(23, 35, 61, 1);
}

.dita-ot-version {
  color: rgba(105, 111, 121, 1);
}

label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
</style>
