var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modaloutputprogress",attrs:{"id":"modal-progress","title":"Processing","title-class":"font-18","hide-header":"","hide-footer":"","hide-close":"","no-close-on-backdrop":"","no-close-on-esc":""}},[_c('strong',[_vm._v("Please wait")]),_c('br'),_c('p',[_vm._v("Working on customization to receive the desired output.")]),_c('b-progress',{staticClass:"custom-progress",attrs:{"value":_vm.progress,"max":100}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-lg-4 mb-5 user-select-none"},[_c('div',{attrs:{"no-body":""}},[_vm._m(0),_c('div',{staticClass:"border-top"},[_c('div',{staticClass:"categories-group-card"},[_c('a',{staticClass:"categories-group-list card-title",class:{
                active: _vm.currentFormNumber === 1,
                '': _vm.currentFormNumber === 1,
              },on:{"click":function($event){return _vm.changeForm(1)}}},[_c('i',{staticClass:"mdi mdi-apps font-size-18 align-middle mr-2"}),_vm._v(" Themes ")])]),_c('div',{staticClass:"categories-group-card"},[_c('a',{staticClass:"categories-group-list card-title",class:{
                active: _vm.currentFormNumber === 2,
                '': _vm.currentFormNumber === 2,
              },attrs:{"aria-controls":"collapseTwo"},on:{"click":function($event){return _vm.changeForm(2)}}},[_c('i',{staticClass:"mdi mdi-page-layout-header font-size-18 align-middle mr-2"}),_vm._v(" Header ")])]),_c('div',{staticClass:"categories-group-card"},[_c('a',{staticClass:"categories-group-list card-title",class:{
                active: _vm.currentFormNumber === 3,
                '': _vm.currentFormNumber === 3,
              },attrs:{"data-toggle":"collapse","aria-expanded":"false","aria-controls":"collapseThree"},on:{"click":function($event){return _vm.changeForm(3)}}},[_c('i',{staticClass:"mdi mdi-format-list-bulleted font-size-18 align-middle mr-2"}),_vm._v(" Navigation Panel ")])]),(!_vm.disabledTable)?_c('div',{staticClass:"categories-group-card"},[_c('a',{staticClass:"categories-group-list card-title",class:{
                active: _vm.currentFormNumber === 4,
                '': _vm.currentFormNumber === 4,
              },attrs:{"data-toggle":"collapse","aria-expanded":"false","aria-controls":"collapseFour"},on:{"click":function($event){return _vm.changeForm(4)}}},[_c('i',{staticClass:"mdi mdi-table font-size-18 align-middle mr-2"}),_vm._v(" Tables ")])]):_vm._e(),(_vm.disabledTable)?_c('div',{staticClass:"categories-group-card"},[_c('a',{staticClass:"categories-group-list card-title",class:{
                active: _vm.currentFormNumber === 5,
                '': _vm.currentFormNumber === 5,
              },attrs:{"data-toggle":"collapse","aria-expanded":"false","aria-controls":"collapseFour"},on:{"click":function($event){return _vm.changeForm(5)}}},[_c('i',{staticClass:"mdi mdi-table font-size-18 align-middle mr-2"}),_vm._v(" Customized options ")])]):_vm._e()])])]),_c('div',{staticClass:"col-lg-9 border-left"},[_c('div',{attrs:{"no-body":""}},[_c('div',{staticClass:"custom-html-styler d-flex justify-content-between align-items-center flex-wrap"},[_c('div',{staticClass:"custom-title mb-0"},[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.childData.title))])])]),_c('div',{staticClass:"mx-1 border-top"},[_c('b-card-sub-title',{staticClass:"mt-3 mb-4"},[_c('h6',{staticClass:"card-subtitle text-center"},[_vm._v(" "+_vm._s(_vm.childData.paragraph)+" ")])]),(_vm.currentFormNumber > 0 && _vm.currentFormNumber <= _vm.totalForms)?_c('div',[_c(_vm.currentForm,{tag:"component",attrs:{"formDataList":_vm.formDataList,"title":_vm.title,"form-number":_vm.currentFormNumber,"selectedLogoFile":_vm.selectedLogoFile},on:{"form-submitnew":_vm.handleformSubmit,"data-mounted":_vm.handleChildData,"next-form":_vm.handlenextbtn,"prev-form":_vm.handlePreviousBtn,"final-submit":_vm.finalSubmit,"fileSelected":_vm.handleFileSelected}})],1):_c('div',[_c('p',[_vm._v("No form found")])])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-html-styler d-flex justify-content-between align-items-center flex-wrap"},[_c('div',{staticClass:"custom-title mb-0"},[_vm._v("HTML STYLER")])])
}]

export { render, staticRenderFns }